import React, { useEffect } from "react";
import { BREAKPOINTS, Button, TextLink, rem, useLang } from "@manulife/mux";

import Labels from "../../assets/labels";

import RtgSelectedProduct from "./Fields/RtgSelectedProduct";
import RtgCostType from "./Fields/RtgCostType";
import RtgAmount from "./Fields/RtgAmount";
import RtgAge from "./Fields/RtgAge";
import RtgSex from "./Fields/RtgSex";
import RtgHealthStyle from "./Fields/RtgHealthStyle";
import RtgSynClientName from "./Fields/RtgSynClientName";
import {
  getAmountNumbers,
  getMinMaxAmountLimit,
  replaceKeysWithValues,
  addCurrencySign,
} from "../../services/helpers/commonHelpers";
import { NormalText } from "../../assets/appStyledComponents";
import RtgSynChildProtectionRiders from "./Fields/RtgSynChildProtectionRiders";
import { useFormContext } from "react-hook-form";
import {
  eIsChildProtection,
  eProduct,
} from "../../services/helpers/udmGenerator";
import SynergyChildDetails from "./SynergyChildDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportSuccess } from "../../appRedux/report/Actions";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

function renderAgeAmount(selectedLang, isGI, rtgAge) {
  if (isGI) {
    return (
      <>
        <RtgAge />
        {renderAmount(selectedLang, isGI, rtgAge)}
      </>
    );
  } else {
    return (
      <>
        {renderAmount(selectedLang, isGI, rtgAge)}
        <RtgAge />
      </>
    );
  }
}
const getAmountLabel = (selectedLang, isGI, age) => {
  let amountLabel = `${Labels.amount[`${selectedLang}`].label} `;
  const ageMinGI = 18;
  const ageMaxGI = 75;
  const isAge = age && age >= ageMinGI && age <= ageMaxGI;
  if (isGI) {
    if (isAge){
      const { min, max } = getMinMaxAmountLimit(eProduct.GI, age);
      amountLabel = `${Labels.amountGI[`${selectedLang}`].label} ${replaceKeysWithValues(
        Labels.amountSubText[`${selectedLang}`],
        {
          min_amount: addCurrencySign(min, selectedLang),
          max_amount: addCurrencySign(max, selectedLang),
        }
      )} `;
    } else{
      amountLabel = `${Labels.amountGI[`${selectedLang}`].label} `;
    }
  }
  return amountLabel;
};

function renderAmount(selectedLang, isGI, age) {
  return (
    <RtgAmount
      fieldName={"rtgAmount"}
      fieldLabel={getAmountLabel(selectedLang, isGI, age)}
      fieldAriaLabel={isGI ? Labels.amountGI[`${selectedLang}`].label : Labels.amount[`${selectedLang}`].label}
      validations={{
        required: Labels.errors.required[`${selectedLang}`],
        validate: (value, { rtgSelectedProduct }) => {
          const { min, max } = getMinMaxAmountLimit(rtgSelectedProduct, age);
          const valueToCheck = Number(getAmountNumbers(value));
          if (valueToCheck < min || valueToCheck > max) {
            return replaceKeysWithValues(
              Labels.errors.amount[`${selectedLang}`],
              {
                min_amount: addCurrencySign(
                  getAmountNumbers(min, selectedLang),
                  selectedLang
                ),
                max_amount: addCurrencySign(
                  getAmountNumbers(max, selectedLang),
                  selectedLang
                ),
              }
            );
          }
        },
      }}
    />
  );
}

function RtgInputs() {
  const { num16 } = NumberConstants;
  const selectedLang = useLang();
  const dispatch = useDispatch();
  const illustrationIsLoading = useSelector(
    (state) => state.rportReducer?.isLoading
  );
  const { watch, setValue, reset, getValues } = useFormContext(); // retrieve all hook methods

  const rtgSelectedProduct = watch("rtgSelectedProduct");
  const rtgSynChildProtectionRiders = watch("rtgSynChildProtectionRiders");
  const rtgAge = watch("rtgAge");
  const isGI = rtgSelectedProduct===eProduct.GI;

  useEffect(() => {
    dispatch(fetchReportSuccess({}));
    const selectedProduct = getValues("rtgSelectedProduct");
    reset({
      rtgSelectedProduct: selectedProduct,
    });
    return () => true;
  }, [rtgSelectedProduct, dispatch, reset, getValues]);

  useEffect(() => {
    if (rtgSynChildProtectionRiders !== eIsChildProtection.yes) {
      setValue("rtgSynChildCount", "");
    }
    return () => true;
  }, [rtgSynChildProtectionRiders, setValue]);

  return (
    <>
      <RtgSelectedProduct />
      {!isGI && (<RtgCostType />)}
      {renderAgeAmount(selectedLang, isGI, rtgAge)}
      <RtgSex fieldName={"rtgSex"} />
      <RtgHealthStyle />
      {rtgSelectedProduct === eProduct.SYN ? (
        <>
          <RtgSynClientName />
          <RtgAmount
            fieldName={"rtgSynTermRiderAmount"}
            fieldLabel={
              <>
                {Labels.termRiderAmount[`${selectedLang}`].label}{" "}
                <NormalText style={{ display: "inline" }}>
                  ({Labels.optional[`${selectedLang}`].label})
                </NormalText>
              </>
            }
            fieldAriaLabel={Labels.termRiderAmount[`${selectedLang}`].label}
            validations={{
              validate: (value) => {
                const { min, max } = { min: 100000, max: 2000000 };
                const valueToCheck = Number(getAmountNumbers(value));
                if (valueToCheck > 0) {
                  if (valueToCheck < min || valueToCheck > max) {
                    return replaceKeysWithValues(
                      Labels.errors.amount[`${selectedLang}`],
                      {
                        min_amount: addCurrencySign(
                          getAmountNumbers(min, selectedLang),
                          selectedLang
                        ),
                        max_amount: addCurrencySign(
                          getAmountNumbers(max, selectedLang),
                          selectedLang
                        ),
                      }
                    );
                  }
                }
              },
            }}
          />
          <RtgSynChildProtectionRiders />
        </>
      ) : null}
      {rtgSynChildProtectionRiders === eIsChildProtection.yes ? (
        <SynergyChildDetails />
      ) : null}

      <Button
        data-testid="calculateBtn"
        saving={illustrationIsLoading}
        disabled={illustrationIsLoading}
        customStyle={{
          buttonStyle: {
            marginTop: rem(num16),
            media: [
              {
                maximum: BREAKPOINTS.TABLET_PORTRAIT,
                width: "100%",
              },
            ],
          },
        }}
        ariaLabel={Labels.calculate[`${selectedLang}`]}
      >
        {Labels.calculate[`${selectedLang}`]}
      </Button>
      <TextLink to={Labels.manulifeLink[selectedLang].url} isExternal>
        {Labels.manulifeLink[selectedLang].label}{" "}
      </TextLink>
    </>
  );
}

export default RtgInputs;
