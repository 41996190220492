import {
  Button,
  Card,
  Stack,
  colors,
  useLang,
  TextLink,
  VIEWPORTS,
  rem,
} from "@manulife/mux";
import { useEffect, useState } from "react";
import { Download, Email } from "@manulife/mux-cds-icons";
import { useDispatch, useSelector } from "react-redux";
import { emailResults } from "../../services/helpers/emailTemplate";
import { formatDate, downloadPDF } from "../../services";
import { eProduct } from "../../services/helpers/udmGenerator";
import {
  BoldLebalText,
  BoldValueText,
  NormalText,
} from "../../assets/appStyledComponents";
import SynergyURLs from "../../assets/synergyURLs.json";
import GIURLs from "../../assets/GIURLs.json";
import ResultLables from "../../assets/resultLabels.json";
import useWindowSize from "../../customHooks/useWindowSize";
import { formatAmount } from "../../services/helpers/commonHelpers";
import { fetchReport } from "../../appRedux/report/Actions";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

function InitialPremiumTab() {
  const { num15, num2 } = NumberConstants;
  const selectedLang = useLang();
  const dispatch = useDispatch();

  const illustration = useSelector(
    (state) => state.rportReducer?.data?.illustration
  );

  const isDownloading = useSelector(
    (state) => state.rportReducer?.isDownloading
  );

  const premiumResult = useSelector(
    (state) =>
      state.rportReducer?.data?.illustration?.policy?.premiumResult || []
  );

  const coverage = useSelector(
    (state) => state.rportReducer?.data?.illustration?.policy?.coverage || []
  );

  const baseCovergaeObj = coverage.find((ele) => ele.indicatorCode === "Base");
  const validatedData = useSelector(
    (state) => state.rportReducer?.validatedData
  );

  const reportPdfBase64str = useSelector(
    (state) => state.rportReducer?.data?.illustration?.report?.data
  );

  const [downloadClicked, setDownloadClicked] = useState(false);

  useEffect(() => {
    if (reportPdfBase64str && downloadClicked) {
      setDownloadClicked(false);
      downloadPDF(reportPdfBase64str);
    }
  }, [reportPdfBase64str, downloadClicked]);

  const getResultText = (product) => {
    switch (product) {
      case "GuaranteedIssue":
        return ResultLables.GIResultText[`${selectedLang}`].label;

      case "FamilyTerm":
        return !("vitality" in illustration?.policy)
          ? ""
          : ResultLables.FTVResultText[`${selectedLang}`].label;

      case "Lifecheque":
        return ResultLables.LCResultText[`${selectedLang}`].label;

      case "Synergy":
        return ResultLables.SYNResultText[`${selectedLang}`].label;
      default:
        return;
    }
  };

  const getURLS = (product) => {
    if (product === eProduct.SYN){
      return (
        <>
          <Card.Content customStyle={customStyle}>
            <TextLink to={SynergyURLs.Advisor[`${selectedLang}`].url} isDocument>
              {SynergyURLs.Advisor[`${selectedLang}`].text}
            </TextLink>
            <br />
            <TextLink
              to={SynergyURLs.Insurability[`${selectedLang}`].url}
              isExternal
              isDocument
            >
              {SynergyURLs.Insurability[`${selectedLang}`].text}
            </TextLink>
            <br />
            <TextLink
              to={SynergyURLs.Commissions[`${selectedLang}`].url}
              isExternal
              isDocument
            >
              {SynergyURLs.Commissions[`${selectedLang}`].text}
            </TextLink>
          </Card.Content>
          <Card.Separator />
        </>
      );
    } else {
      return (
        <>
          <Card.Content customStyle={customStyle}>
            <TextLink
              to={GIURLs.Commissions[`${selectedLang}`].url}
              isExternal
              isDocument
            >
              {GIURLs.Commissions[`${selectedLang}`].text}
            </TextLink>
            <br />
            <TextLink
              to={GIURLs.eApplication[`${selectedLang}`].url}
              isExternal
              isDocument
            >
              {GIURLs.eApplication[`${selectedLang}`].text}
            </TextLink>
          </Card.Content>
          <Card.Separator />
        </>
      );
    }
  };
  const fetchCalculation = () => {
    dispatch(
      fetchReport({
        ...validatedData,
        isDownloadPDF: true,
      })
    );
  };

  const { width: windowWidth } = useWindowSize();
  let customStyle = {
    contentStyle: {},
  };
  if (windowWidth < VIEWPORTS.XS) {
    customStyle = {
      contentStyle: {
        padding: `${rem(num15)}`,
      },
    };
  }
  return (
    <>
      <Card.Content customStyle={customStyle}>
        <NormalText data-testid="dateColumn">
          {formatDate("resultSection", selectedLang)}
        </NormalText>
      </Card.Content>
      <Card.Content customStyle={customStyle}>
        <BoldLebalText data-testid="monthlyAmount">
          {ResultLables.monthly[`${selectedLang}`].label}
        </BoldLebalText>
        <BoldValueText>
          {formatAmount(
            premiumResult.length
              ? Number(
                  premiumResult.find((ele) => ele.depositMode === "Monthly")
                    .policyModalPremium
                ).toFixed(num2)
              : "0",
            selectedLang
          )}
        </BoldValueText>
      </Card.Content>
      <Card.Separator />
      <Card.Content customStyle={customStyle}>
        <BoldLebalText data-testid="annualAmount">
          {ResultLables.annually[`${selectedLang}`].label}
        </BoldLebalText>
        <BoldValueText>
          {formatAmount(
            premiumResult.length
              ? Number(
                  premiumResult.find((ele) => ele.depositMode === "Annually")
                    .policyModalPremium
                ).toFixed(num2)
              : "0",
            selectedLang
          )}
        </BoldValueText>
      </Card.Content>
      {illustration && illustration.policy.product === eProduct.GI && (
        <>
          <Card.Separator />
          <Card.Content customStyle={customStyle}>
            <NormalText>
              {getResultText(illustration.policy.product)}
            </NormalText>
          </Card.Content>
        </>
      )}
      <Card.Separator />
      {illustration && illustration.policy.product === eProduct.SYN && (
        <>
          <Card.Content customStyle={customStyle}>
            <BoldLebalText>
              {ResultLables.SYNBenAmt[`${selectedLang}`].label}
            </BoldLebalText>
          </Card.Content>
          <Card.Content customStyle={customStyle}>
            <BoldLebalText data-testid="disabilityMonthly">
              {ResultLables.disablityMonthly[`${selectedLang}`].label}
            </BoldLebalText>
            <BoldValueText>
              {formatAmount(
                baseCovergaeObj.disabilityBenefit.amount,
                selectedLang
              )}
            </BoldValueText>
          </Card.Content>
          <Card.Content customStyle={customStyle}>
            <BoldLebalText data-testid="criticalIlliness">
              {ResultLables.criticalIlliness[`${selectedLang}`].label}
            </BoldLebalText>
            <BoldValueText>
              {formatAmount(
                baseCovergaeObj.criticalIllnessBenefit.lumpSumAmount,
                selectedLang
              )}
            </BoldValueText>
          </Card.Content>
          <Card.Content customStyle={customStyle}>
            <BoldLebalText data-testid="lifeInsurence">
              {ResultLables.lifeInsurence[`${selectedLang}`].label}
            </BoldLebalText>
            <BoldValueText>
              {formatAmount(baseCovergaeObj.coverageAmount, selectedLang)}
            </BoldValueText>
          </Card.Content>
          <Card.Content customStyle={customStyle}>
            <NormalText>
              {getResultText(illustration.policy.product)}
            </NormalText>
          </Card.Content>
          <Card.Separator />
        </>
      )}

      <Card.Content customStyle={customStyle}>
        <>
          {illustration && (
            <>
              <NormalText>
                {[eProduct.SYN, eProduct.GI].includes(illustration.policy.product)
                  ? getURLS(illustration.policy.product)
                  : getResultText(illustration.policy.product)}
              </NormalText>
              <Stack direction="row" gapX="0">
                <Button
                  data-testid="pdf"
                  variant={Button.VARIANT.TERTIARY_NOICON}
                  icon={<Download color={colors.m_green} />}
                  disabled={isDownloading}
                  saving={isDownloading}
                  onClick={() => {
                    setDownloadClicked(true);
                    if (!reportPdfBase64str) {
                      fetchCalculation();
                    }
                  }}
                >
                  {ResultLables.downloadPDF[`${selectedLang}`].label}
                </Button>
                <Button
                  data-testid="email"
                  variant={Button.VARIANT.TERTIARY_NOICON}
                  icon={<Email color={colors.m_green} />}
                  onClick={async () => {
                    await emailResults(
                      illustration,
                      selectedLang,
                      validatedData
                    );
                  }}
                >
                  {ResultLables.email[`${selectedLang}`].label}
                </Button>
              </Stack>
            </>
          )}
        </>
      </Card.Content>
    </>
  );
}

export default InitialPremiumTab;
