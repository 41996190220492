import {
  eProduct,
  eSmokingStatus,
  eSex,
  eHealthStyle,
  eIsChildProtection,
  eInsuredTypes,
} from "../services/helpers/udmGenerator";

const labels = {
  text: {
    "en-CA": {
      label: "All fields are mandatory unless marked optional.",
    },
    "fr-CA": {
      label: "Sauf indication contraire, tous les champs sont obligatoires",
    },
  },
  product: {
    "en-CA": {
      label: "Product",
      options: [
        {
          label: "Manulife Guaranteed Issue Life",
          value: eProduct.GI,
        },
        {
          label: "Family Term with Vitality Plus™",
          value: eProduct.FTV,
        },
        {
          label: "Synergy",
          value: eProduct.SYN,
        },
        {
          label: "Family Term",
          value: eProduct.FT,
        },
        {
          label: "Lifecheque",
          value: eProduct.LC,
        },
      ],
    },
    "fr-CA": {
      label: "Produit",
      options: [
        {
          label: "Vie Manuvie à émission garantie",
          value: eProduct.GI,
        },
        {
          label: "Temporaire Famille avec Vitalité Plusᴹᶜ",
          value: eProduct.FTV,
        },
        {
          label: "Synergie",
          value: eProduct.SYN,
        },
        {
          label: "Temporaire Famille",
          value: eProduct.FT,
        },
        {
          label: "Chèque-Vie",
          value: eProduct.LC,
        },
      ],
    },
  },
  select: {
    "en-CA": {
      label: "Select",
    },
    "fr-CA": {
      label: "Sélectionnez",
    },
  },
  costType: {
    "en-CA": {
      label: "Cost type",
    },
    "fr-CA": {
      label: "Type de coût",
    },
  },
  amount: {
    "en-CA": {
      label: "Amount",
    },
    "fr-CA": {
      label: "Montant",
    },
  },
  amountGI: {
    "en-CA": {
      label: "Coverage amount",
    },
    "fr-CA": {
      label: "Montant de couverture",
    },
  },
  age: {
    "en-CA": {
      label: "Age",
    },
    "fr-CA": {
      label: "Âge",
    },
  },
  clientName: {
    "en-CA": {
      label: "Client name",
    },
    "fr-CA": {
      label: "Nom du client",
    },
  },
  childName: {
    "en-CA": {
      label: "Name",
    },
    "fr-CA": {
      label: "Nom",
    },
  },
  termRiderAmount: {
    "en-CA": {
      label: "Term rider amount",
    },
    "fr-CA": {
      label: "Montant de la Garantie Assurance temporaire",
    },
  },
  optional: {
    "en-CA": {
      label: "Optional",
    },
    "fr-CA": {
      label: "facultatif",
    },
  },
  ageSubText: {
    "en-CA": "({{min_age}} to {{max_age}})",
    "fr-CA": "({{min_age}} à {{max_age}})",
  },
  amountSubText: {
    "en-CA": "({{min_amount}} to {{max_amount}})",
    "fr-CA": "({{min_amount}} à {{max_amount}})",
  },
  childProtectionRiders: {
    "en-CA": {
      label: "Add child protection riders?",
      options: [
        {
          label: "Yes",
          value: eIsChildProtection.yes,
        },
        {
          label: "No",
          value: eIsChildProtection.no,
        },
      ],
    },
    "fr-CA": {
      label: "Ajouter des garanties Protection des enfants?",
      options: [
        {
          label: "Oui",
          value: eIsChildProtection.yes,
        },
        {
          label: "Non",
          value: eIsChildProtection.no,
        },
      ],
    },
  },
  childCount: {
    "en-CA": {
      label: "How many children would you like to insure?",
      tooltipText:
        "Please reach out to your wholesalers if you need a quote for more than 4 children.",
      options: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
      ],
    },
    "fr-CA": {
      label: "Combien d’enfants aimeriez-vous assurer?",
      tooltipText:
        "Veuillez communiquer avec vos experts-conseils si vous avez besoin d’une soumission pour plus de quatre enfants.",
      options: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
      ],
    },
  },
  ariaChildCount: {
    "en-CA": " How many children would you like to insure tooltip",
    "fr-CA": "Combien d’enfants aimeriez-vous assurer infobulle"
  },
  criticalIllnessAmount: {
    "en-CA": {
      label: "Critical illness amount",
      options: [
        {
          label: "$ 10,000",
          value: "10000",
        },
        {
          label: "$ 5,000",
          value: "5000",
        },
      ],
    },
    "fr-CA": {
      label: "Montant de l’assurance maladies graves",
      options: [
        {
          label: "10 000 $",
          value: "10000",
        },
        {
          label: "5 000 $",
          value: "5000",
        },
      ],
    },
  },
  insuredTypes: {
    "en-CA": {
      options: [
        {
          label: "Life insurance $10,000",
          value: eInsuredTypes.LI,
        },
        {
          label: "Critical illness",
          value: eInsuredTypes.CI,
        },
      ],
    },
    "fr-CA": {
      options: [
        {
          label: "Assurance vie de 10 000 $",
          value: eInsuredTypes.LI,
        },
        {
          label: "Assurance maladies graves",
          value: eInsuredTypes.CI,
        },
      ],
    },
  },
  gender: {
    "en-CA": {
      label: "Sex",
      options: [
        {
          label: "Male",
          value: eSex.male,
        },
        {
          label: "Female",
          value: eSex.female,
        },
      ],
    },
    "fr-CA": {
      label: "Sexe",
      options: [
        {
          label: "Homme",
          value: eSex.male,
        },
        {
          label: "Femme",
          value: eSex.female,
        },
      ],
    },
  },
  healthstyle: {
    "en-CA": {
      label: "Healthstyle",
      options: [
        {
          label: "3",
          value: eHealthStyle.hs3,
        },
        {
          label: "5",
          value: eHealthStyle.hs5,
        },
      ],
    },
    "fr-CA": {
      label: "Indice-santé",
      options: [
        {
          label: "3",
          value: eHealthStyle.hs3,
        },
        {
          label: "5",
          value: eHealthStyle.hs5,
        },
      ],
    },
  },
  smokingStatus: {
    "en-CA": {
      label: "Smoking status",
      options: [
        {
          label: "Non-smoker",
          value: eSmokingStatus.nonsmoker,
        },
        {
          label: "Smoker",
          value: eSmokingStatus.smoker,
        },
      ],
    },
    "fr-CA": {
      label: "Usage du tabac",
      options: [
        {
          label: "Non fumeur",
          value: eSmokingStatus.nonsmoker,
        },
        {
          label: "Fumeur",
          value: eSmokingStatus.smoker,
        },
      ],
    },
  },
  child: {
    "en-CA": "Child",
    "fr-CA": "Enfant",
  },
  calculate: {
    "en-CA": "Calculate",
    "fr-CA": "Calculer",
  },
  title: {
    "en-CA": "Rates to Go",
    "fr-CA": "Taux Express",
  },
  subtitle: {
    "en-CA":
      "Need to calculate insurance rates quickly while you're on the go? Use our online rates calculator below and get insurance rates in an instant.",
    "fr-CA":
      "Vous avez besoin d’obtenir des taux d’assurance rapidement lorsque vous n’êtes pas au bureau? Utilisez notre calculateur de taux en ligne ci-après et obtenez des taux d’assurance en un instant.",
  },
  contactUs: {
    "en-CA": {
      label: "Contact Us",
      url: "https://advisor.manulife.ca/advisors/connect/contact-us.html",
    },
    "fr-CA": {
      label: "Nous joindre",
      url: "https://conseiller.manuvie.ca/conseillers/connexions/pour-nous-joindre.html",
    },
  },
  contactYourWholesaler: {
    "en-CA": {
      label: "Contact your wholesaler",
      url: "https://advisor.manulife.ca/advisors/insurance.html",
    },
    "fr-CA": {
      label: "Communiquez avec votre expert-conseil",
      url: "https://conseiller.manuvie.ca/conseillers/assurance.html",
    },
  },
  Advisor: {
    "en-CA": "Advisor Portal",
    "fr-CA": "Portail des conseillers",
  },
  Profile: {
    "en-CA": "Profile",
    "fr-CA": "Profil",
  },
  copyrightTextFooter: {
    "en-CA": "© The Manufacturers Life Insurance Company, 1999-2024.",
    "fr-CA": "© La Compagnie d'Assurance-Vie Manufacturers, 1999-2024.",
  },
  Privacy: {
    "en-CA": {
      label: "Privacy",
      url: "https://www.manulife.ca/privacy-policies.html",
    },
    "fr-CA": {
      label: "Politique de confidentialité",
      url: "https://www.manuvie.ca/politiques-de-confidentialite.html",
    },
  },
  Legal: {
    "en-CA": {
      label: "Legal",
      url: "https://www.manulife.com/en/legal.html",
    },
    "fr-CA": {
      label: "Notice juridique",
      url: "https://www.manulife.com/fr/legal.html",
    },
  },
  Accessibility: {
    "en-CA": {
      label: "Accessibility",
      url: "https://www.manulife.ca/about-us/accessibility.html",
    },
    "fr-CA": {
      label: "Accessibilité",
      url: "https://www.manuvie.ca/a-propos-de-nous/accessibilite.html",
    },
  },
  Disclaimer: {
    "en-CA": {
      label: "Disclaimer",
    },
    "fr-CA": {
      label: "Avis important",
    },
  },
  errors: {
    apiFailed: {
      "en-CA":
        "There is a connection issue and we can’t complete the illustration at this time. Close the program and try again. If the problem continues, please contact Manulife.",
      "fr-CA":
        "Un problème de connexion nous empêche actuellement de créer le projet informatisé. Veuillez fermer le programme et essayer de nouveau. Si le problème persiste, communiquez avec Manuvie.",
    },
    required: {
      "en-CA": "Required",
      "fr-CA": "Obligatoire",
    },
    amount: {
      "en-CA": "Must be between {{min_amount}} and {{max_amount}}",
      "fr-CA":
        "Le montant doit se situer entre {{min_amount}} et {{max_amount}}",
    },
    age: {
      "en-CA": "Must be between {{min_age}} and {{max_age}}",
      "fr-CA": "Le montant doit se situer entre {{min_age}} et {{max_age}}",
    },
    childAge: {
      "en-CA": "Must be between {{min_age}} days and {{max_age}} years",
      "fr-CA": "Doit être entre {{min_age}} jours et {{max_age}} ans",
    },
    childInsuranceType: {
      "en-CA": "Must select at least one option",
      "fr-CA": "Vous devez sélectionner au moins une option",
    },
  },
  ProductOtIllustration: {
    "en-CA": {
      label: "Products to illustrate",
      url: "https://www.insurance.manulife.ca",
    },
    "fr-CA": {
      label: "Produits qui feront l'objet d'un projet informatisé",
      url: "https://id.manulife.ca/advisor/signin?ui_locales=fr-CA",
    },
  },
  Pay: {
    "en-CA": "15 Pay",
    "fr-CA": "15 pmts",
  },
  Level: {
    "en-CA": "Level",
    "fr-CA": "Uniforme",
  },
  LearnMore: {
    "en-CA": "Learn More",
    "fr-CA": "En savoir plus",
  },
  ariaAgeLearnMore: {
    "en-CA": "Age Learn More ",
    "fr-CA": "Âge En savoir plus",
  },
  ariaHealthstyleLearnMore: {
    "en-CA": "Smoking status Learn More",
    "fr-CA": "Usage du tabac En savoir plus",
  },
  disclaimer: {
    "en-CA": "Disclaimer: Important information about this quote",
    "fr-CA": "Avertissement : Renseignements importants sur ce devis",
  },
  manulifeLink: {
    "en-CA": {
      label: "Manulife Illustrations",
      url: "https://www.insurance.manulife.ca/illustrations/",
    },
    "fr-CA": {
      label: "Projets informatisés de Manuvie",
      url: "https://www.insurance.manulife.ca/illustrations/",
    },
  },
};
export default labels;
