import {
    LANG,
    H3,
    P,
    rem,
    I,

} from "@manulife/mux";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

const DisclaimerContent = (lang) => {
    const { num11 } = NumberConstants;
    const superscript = {
        position: 'relative',
        top: '-0.5em',
        fontSize: rem(num11)
    };

    if (lang === LANG.EN_CA) {
        return (
            <>
                <P>Manulife’s Rates to Go illustrates insurance premiums based on the information provided. This website does not provide an offer to insure. Applications are subject to underwriting. Premium rates are subject to change without notice. For additional information on Family Term, Family Term with <I>Vitality Plus</I><span style={superscript}>TM</span>, and Lifecheque, refer to Manulife Illustrations, accessible through the Advisor Portal(sign in required). </P>
                {getGuaranteedIssueLifeDiscliamer(lang)}
                {getLifechequeDiscliamer(lang)}
                <H3>Family Term - T10 or T20</H3>
                <P>Only initial premiums are illustrated. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. Premiums for this product increase at renewal. While renewal premiums are guaranteed at issue, they are not illustrated through this tool. Premiums illustrated include policy fees. </P>
                <H3>Family Term - T65</H3>
                <P>T65 provides insurance coverage until age 65. Premiums remain the same(level) for the life of the coverage. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. Premiums illustrated include policy fees. </P>
                <H3>Synergy - T10</H3>
                <P>Synergy includes a life insurance policy, a disability insurance policy, and a critical illness insurance policy in one solution. Only initial premiums are illustrated. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. Premiums for this product increase at renewal. While renewal premiums are guaranteed at issue, they are not illustrated through this tool. </P>
                <H3>Synergy - Level</H3>
                <P>Synergy includes a life insurance policy, a disability insurance policy, and a critical illness insurance policy in one solution. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. </P>
                <H3>Synergy - Term insurance rider(TIR)</H3>
                <P>Your TIR provides additional life insurance protection. It is low - cost, renewable, and convertible term insurance to help meet personal and business insurance needs. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. The premium rate of the rider changes at every 10th coverage anniversary. Your TIR can be converted to permanent life insurance until your Synergy solution ends. All TIR coverages end when the Synergy solution ends. </P>
                <H3>Synergy - Child protection rider(CPR) – Critical Illness(CI)</H3>
                <P>The CI CPR provides benefits when the child who is insured on the rider coverage suffers a covered condition that is insured under the rider. The CI CPR contains specific definitions for the covered conditions and the requirements you must satisfy to receive benefits. Please review the rider for full details. Coverage continues until the Synergy anniversary nearest the child’s 25th birthday. </P>
                <P>We continue any rider coverages in effect at no cost if the Synergy solution ends because of any of the following:</P>
                <ul>
                    <li><P>The insured person dies and a death benefit is payable. </P></li>
                    <li><P>The available amount of insurance is reduced to 0. </P></li>
                    <li><P>The Synergy solution reaches its expiry date. </P></li>
                </ul>
                <P>All benefits provided by the rider coverage will continue, unchanged, until the rider coverage expires. </P>
                <P>Premiums remain the same(level) for the duration of the coverage. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. </P>
                <H3>Synergy - Child protection rider(CPR) – Life</H3>
                <P>Each coverage under the CPR - Life insures the life of 1 child and pays a death benefit if the child insured on the rider dies. This rider also provides a guaranteed life insurability benefit and a critical illness insurability benefit. Coverage continues until the Synergy anniversary nearest the child’s 25th birthday. </P>
                <P>We continue any rider coverages in effect at no cost if the Synergy solution ends because of any of the following:</P>
                <ul>
                    <li><P>The insured person dies, and a death benefit is payable. </P></li>
                    <li><P>The available amount of insurance is reduced to 0. </P></li>
                    <li><P>The Synergy solution reaches its expiry date. </P></li>
                </ul>
                <P>All benefits provided by the rider coverage will continue, unchanged, until the rider coverage expires. </P>
                <P>Premiums remain the same(level) for the duration of the coverage. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. </P>
                <H3>Family Term with <I>Vitality Plus</I><span style={superscript}>TM</span> - T10 or T20</H3>
                <P>Only first year premiums are illustrated. There are four levels of Vitality Status™: Platinum, Gold, Silver, and Bronze. Each Status has a corresponding guaranteed savings percentage. Every Family Term with <I>Vitality Plus</I> insurance coverage automatically starts with a Vitality® premium savings of 10 % in its first year. During the premium adjustment period and after the first coverage anniversary, actual premiums are based on the Vitality Status™ achieved by the insured person. Actual premiums may vary from what’s illustrated in Rates to Go but will never be more than the guaranteed maximum premiums set out in your contract when issued. Premiums illustrated include the policy fee and Vitality charge. </P>
                <P>Insurance products are issued by The Manufacturers Life Insurance Company. The Vitality Group International Inc. , in association with The Manufacturers Life Insurance Company, provides the Manulife Vitality program. Vitality and Vitality Status™ are trademarks of The Vitality Group International, Inc. , and are used by The Manufacturers Life Insurance Company and its affiliates under license. Please consult your financial representative to learn more and find out if you qualify for this product. </P>
                <H3>Family Term with <I>Vitality Plus</I><span style={superscript}>TM</span> – T65</H3>
                <P>Only first year premiums are illustrated. T65 provides insurance coverage until age 65. There are four levels of Vitality Status™: Platinum, Gold, Silver, and Bronze. Each Status has a corresponding guaranteed savings percentage. Every Family Term with <I>Vitality Plus</I> insurance coverage automatically starts with a Vitality® premium savings of 10 % in its first year. During the premium adjustment period and after the first coverage anniversary, actual premiums are based on the Vitality Status™ achieved by the insured person. Actual premiums may vary from what’s illustrated in Rates to Go but will never be more than the guaranteed maximum premiums set out in your contract when issued. Premiums illustrated include the policy fee and Vitality charge. </P>
                <P>Insurance products are issued by The Manufacturers Life Insurance Company. The Vitality Group International Inc. , in association with The Manufacturers Life Insurance Company, provides the Manulife Vitality program. Vitality and Vitality Status™ are trademarks of The Vitality Group International, Inc. , and are used by The Manufacturers Life Insurance Company and its affiliates under license. Please consult your financial representative to learn more and find out if you qualify for this product. </P>
            </>
        );
    } else {
        return (<>
            <P>Taux Express de Manuvie illustre les primes d’assurance en fonction des renseignements fournis. Ce site Web n’a pas pour but d’offrir de l’assurance. Les propositions font l’objet d’une tarification. Les taux de prime peuvent être modifiés sans préavis. Pour obtenir plus de renseignements sur les produits Temporaire Famille, Temporaire Famille avec <I>Vitalité Plus</I><span style={superscript}>MC</span> et Chèque-vie, consultez l’outil Projets informatisés de Manuvie, accessible à partir du Portail des conseillers (ouverture de session requise).</P>
            {getGuaranteedIssueLifeDiscliamer(lang)}
            {getLifechequeDiscliamer(lang)}
            <H3>Temporaire Famille – T10 ou T20</H3>
            <P>Seules les primes initiales sont illustrées. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi. Les primes de ce produit augmentent au renouvellement. Les primes renouvelables sont garanties au moment de l’établissement, mais ne sont pas illustrées au moyen de cet outil. Les primes illustrées comprennent les frais de contrat.</P>
            <H3>Temporaire Famille – T65</H3>
            <P>La Temporaire jusqu’à 65 ans procure une couverture d’assurance jusqu’à l’âge de 65 ans. Les primes demeurent uniformes durant toute la durée de la couverture. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi. Les primes illustrées comprennent les frais de contrat.</P>
            <H3>Synergie – T10</H3>
            <P>Synergie réunit un contrat d’assurance vie, un contrat d’assurance invalidité et un contrat d’assurance maladies graves en une seule solution. Seules les primes initiales sont illustrées. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi. Les primes de ce produit augmentent au renouvellement. Les primes renouvelables sont garanties au moment de l’établissement, mais ne sont pas illustrées au moyen de cet outil.</P>
            <H3>Synergie – Uniforme</H3>
            <P>Synergie réunit un contrat d’assurance vie, un contrat d’assurance invalidité et un contrat d’assurance maladies graves en une seule solution. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi.</P>
            <H3>Synergie – Garantie Assurance temporaire (GAT)</H3>
            <P>La GAT vous procure une protection d’assurance vie supplémentaire. Il s’agit d’une assurance temporaire à faible coût, renouvelable et transformable qui répond aux besoins en assurance individuelle et d’entreprise. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi. Le taux de prime de la garantie complémentaire change à chaque 10e anniversaire de couverture. Votre GAT peut être transformée en assurance vie permanente jusqu’à la fin de la solution Synergie. Toute GAT prend fin à la fin de la solution Synergie. </P>
            <H3>Synergie – Garantie Protection des enfants (GPE) – Assurance maladies graves</H3>
            <P>La GPE (assurance maladies graves) prévoit le versement d’une prestation lorsque l’enfant assuré au titre de la couverture de garantie complémentaire est atteint d’une affection couverte par la garantie. La GPE (assurance maladies graves) donne une définition précise des affections couvertes et indique les exigences à satisfaire pour avoir droit aux prestations. Pour en savoir plus, veuillez consulter la garantie complémentaire. La couverture prend fin à l’anniversaire de la solution Synergie le plus proche du 25e anniversaire de naissance de l’enfant. </P>
            <P>Nous maintenons toute couverture de garantie complémentaire en vigueur, sans frais, si la solution Synergie prend fin pour l’une des raisons suivantes :</P>
            <ul>
                <li><P>L’assuré décède et un capital-décès est payable.</P></li>
                <li><P>Le montant d’assurance disponible est réduit à zéro.</P></li>
                <li><P>La solution Synergie arrive à sa date d’expiration.</P></li>
            </ul>
            <P>Toute protection prévue par la couverture de garantie complémentaire demeure inchangée jusqu’à l’expiration de la garantie complémentaire.</P>
            <P>Les primes demeurent uniformes pendant toute la durée de la couverture. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi.</P>
            <H3>Synergie – Garantie Protection des enfants (GPE) – Assurance vie</H3>
            <P>Chaque couverture au titre de la GPE (assurance vie) assure la vie d’un enfant et prévoit le versement d’un capital-décès si celui-ci décède. Cette garantie complémentaire prévoit également une Option d’assurabilité garantie – Vie et une Option d’assurabilité – Maladies graves. La couverture prend fin à l’anniversaire de la solution Synergie le plus proche du 25e anniversaire de naissance de l’enfant. </P>
            <P>Nous maintenons toute couverture de garantie complémentaire en vigueur, sans frais, si la solution Synergie prend fin pour l’une des raisons suivantes :</P>
            <ul>
                <li><P>L’assuré décède et un capital-décès est payable.</P></li>
                <li><P>Le montant d’assurance disponible est réduit à zéro.</P></li>
                <li><P>La solution Synergie arrive à sa date d’expiration.</P></li>
            </ul>
            <P>Toute protection prévue par la couverture de garantie complémentaire demeure inchangée jusqu’à l’expiration de la garantie complémentaire.</P>
            <P>Les primes demeurent uniformes pendant toute la durée de la couverture. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi.</P>
            <H3>Temporaire Famille avec <I>Vitalité Plus</I><span style={superscript}>MC</span> – T10 ou T20</H3>
            <P>Seules les primes de la première année sont illustrées. Il existe quatre niveaux Vitalité<span style={superscript}>MC</span> : Platine, Or, Argent et Bronze. Chaque niveau est associé à un pourcentage de rabais garanti. Chaque assurance Temporaire Famille avec <I>Vitalité Plus</I> commence automatiquement avec un rabais de 10 % sur les primes Vitalité<span style={superscript}>MD</span> de la première année. Pendant la période de rajustement de la prime et après le premier anniversaire de couverture, les primes réelles sont fonction du niveau Vitalité<span style={superscript}>MC</span> de la personne assurée. Les primes réelles peuvent différer de ce qui est indiqué dans l’outil Taux express, mais elles ne dépasseront jamais les primes maximales garanties que le contrat stipule au moment de son établissement. Les primes illustrées comprennent les frais de contrat et la charge Vitalité.</P>
            <P>Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. The Vitality Group International Inc. offre le programme Manuvie Vitalité conjointement avec La Compagnie d’Assurance-Vie Manufacturers. Les noms Vitalité et niveau Vitalité<span style={superscript}>MC</span> sont des marques de commerce de The Vitality Group International, Inc., que La Compagnie d’Assurance-Vie Manufacturers et ses sociétés affiliées utilisent sous licence. Veuillez consulter un représentant financier pour en savoir plus ou pour déterminer si vous y êtes admissible.</P>
            <H3>Temporaire Famille avec <I>Vitalité Plus</I><span style={superscript}>MC</span> – T65</H3>
            <P>Seules les primes de la première année sont illustrées. La Temporaire jusqu’à 65 ans procure une couverture d’assurance jusqu’à l’âge de 65 ans. Il existe quatre niveaux Vitalité<span style={superscript}>MC</span> : Platine, Or, Argent et Bronze. Chaque niveau est associé à un pourcentage de rabais garanti. Chaque assurance Temporaire Famille avec <I>Vitalité Plus</I> commence automatiquement avec un rabais de 10 % sur les primes Vitalité<span style={superscript}>MD</span> de la première année. Pendant la période de rajustement de la prime et après le premier anniversaire de couverture, les primes réelles sont fonction du niveau Vitalité<span style={superscript}>MC</span> de la personne assurée. Les primes réelles peuvent différer de ce qui est indiqué dans l’outil Taux express, mais elles ne dépasseront jamais les primes maximales garanties que le contrat stipule au moment de son établissement. Les primes illustrées comprennent les frais de contrat et la charge Vitalité.</P>
            <P>Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. The Vitality Group International Inc. offre le programme Manuvie Vitalité conjointement avec La Compagnie d’Assurance-Vie Manufacturers. Les noms Vitalité et niveau Vitalité<span style={superscript}>MC</span> sont des marques de commerce de The Vitality Group International, Inc., que La Compagnie d’Assurance-Vie Manufacturers et ses sociétés affiliées utilisent sous licence. Veuillez consulter un représentant financier pour en savoir plus ou pour déterminer si vous y êtes admissible.</P>
        </>
        );
    }
}

//GI Disclaimer
const getGuaranteedIssueLifeDiscliamer = (lang) => {
    if (lang === LANG.EN_CA) {
        return (
            <>
                <H3>Manulife Guaranteed Issue Life</H3>
                <P>Manulife Guaranteed Issue Life is whole life insurance with guaranteed level premiums payable until the policy anniversary nearest to the insured person’s 100th birthday. The policy continues free of charge after that date. Your guaranteed premium will be shown in the contract when it is issued. Premiums illustrated include policy fees. </P>
            </>
        );
    } else {
        return (
            <>
                <H3>Vie Manuvie à émission garantie</H3>
                <P>L’assurance Vie Manuvie à émission garantie est une assurance vie entière avec des primes nivelées garanties payables jusqu’à l’anniversaire contractuel le plus proche du 100e anniversaire de naissance de la personne assurée. Après cette date, le contrat est maintenu gratuitement. Votre prime garantie sera indiquée dans le contrat lors de son émission. Les primes illustrées comprennent les frais de contrat.</P>
            </>
        );
    }
};

const getLifechequeDiscliamer = (lang) => {

    if (lang === LANG.EN_CA) {
        return (<>
            <H3>Lifecheque - T10 or T20 with Return of premium on death rider</H3>
            <P>Only initial premiums are illustrated. Guaranteed premiums are established during the underwriting process and shown in your contract when issued. Premiums for this product increase at renewal. While renewal premiums are guaranteed at issue, they are not illustrated through this tool. Premiums illustrated include policy fees. </P>
            <H3>Lifecheque - T65, T75, T100 and 15 pay with Return of premium on death rider</H3>
            <P>Guaranteed premiums are established during the underwriting process and shown in your contract when issued. Premiums illustrated include policy fees. </P>
        </>);
    }
    else {
        return (<>
            <H3>Chèque-vie – T10 ou T20 avec garantie Remboursement des primes au décès</H3>
            <P>Seules les primes initiales sont illustrées. Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi. Les primes de ce produit augmentent au renouvellement. Les primes renouvelables sont garanties au moment de l’établissement, mais ne sont pas illustrées au moyen de cet outil. Les primes illustrées comprennent les frais de contrat.</P>
            <H3>Chèque-vie – T65, T75 ou T100 et payable pendant 15 ans avec garantie Remboursement des primes au décès</H3>
            <P>Les primes garanties seront déterminées durant le processus de tarification et indiquées dans votre contrat lorsqu’il sera établi. Les primes illustrées comprennent les frais de contrat.</P></>
        );
    }
};

export default DisclaimerContent;